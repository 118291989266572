import * as React from 'react';
import { mediumBlack } from '../../consts/variables';
import { __ } from '../../helpers/TranslationService';
import { SearchIcon } from '../Icons/SearchIcon';

const styles: Record<string, React.CSSProperties> = {
    wrapper: {
        padding: 5,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: 40,
        position: 'relative',
    },
    text: {
        color: mediumBlack,
    },
    icon: {
        marginRight: 5,
    },
};

interface IProps {
    placeholder: string;
}
export function SelectedSearchableValue(props: IProps) {
    return (
        <div style={styles.wrapper}>
            <SearchIcon style={styles.icon} />
            <span className="blinking-cursor">|</span>
            <span style={styles.text}>{__(props.placeholder, 'dreamlines')}</span>
        </div>
    );
}
