import * as React from 'react';

interface IProps {
    onClickOutside(e: React.EventHandler<any>): void;
    style?: any;
    children: JSX.Element | JSX.Element[];
}

export class ClickOutside extends React.Component<IProps, {}> {
    container: any;
    isTouch: boolean = false;

    constructor(props) {
        super(props);
        this.isTouch = false;
    }

    getContainer = ref => {
        this.container = ref;
    };

    componentDidMount() {
        document.addEventListener('touchend', this.handle, true);
        document.addEventListener('click', this.handle, true);
    }

    componentWillUnmount() {
        document.removeEventListener('touchend', this.handle, true);
        document.removeEventListener('click', this.handle, true);
    }

    handle = e => {
        if (e.type === 'touchend') {
            this.isTouch = true;
        }
        if (e.type === 'click' && this.isTouch) {
            return;
        }
        const el = this.container;
        if (!el.contains(e.target)) {
            this.props.onClickOutside(e);
        }
    };

    render() {
        return (
            <div ref={this.getContainer} style={this.props.style}>
                {this.props.children}
            </div>
        );
    }
}
