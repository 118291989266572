export const filterOption = (option, filter) => {
    const lowercasedFilter = filter.toLowerCase();
    const { label, short_title } = option;
    const labelHasString = label && label.toLowerCase().indexOf(lowercasedFilter) > -1;
    const shortTitleHasString =
        short_title && short_title.toLowerCase().indexOf(lowercasedFilter) > -1;

    return lowercasedFilter.length > 0
        ? Boolean(
              !option.disabled &&
                  !option.excludeFromSearch &&
                  (labelHasString || shortTitleHasString),
          )
        : true;
};
