import * as React from 'react';
import * as vars from '../../consts/variables';
import { darkGray } from '../../consts/variables';
import { filterOption } from '../../helpers/customSelectHelpers';
import { TestSelector } from '../../helpers/TestSelector';
import { __ } from '../../helpers/TranslationService';
import { ClickOutside } from '../ClickOutside';
import { SelectedSearchableValue } from './SelectedSearchableValue';
import Select from 'dreamlines-react-select';

const styles: Record<string, React.CSSProperties> = {
    selectedValue: {
        minHeight: 40,
        width: '100%',
        backgroundColor: vars.white,
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        justifyContent: 'space-between',
        border: `1px solid ${darkGray}`,
    },
    optionsWrapper: {
        position: 'relative',
    },
    options: {
        position: 'absolute',
        width: '100%',
    },
    optionsContent: {
        position: 'relative',
        zIndex: 1,
    },
};

const LIST_MAX_HEIGHT = 290;
const FILTER_BUTTON_HEIGHT = 80;
const SEARCH_BAR_HEIGHT = 40;
const HEADER_HEIGHT = 90;

export interface ICustomSelectProps {
    className?: string;
    name?: string;
    value: any;
    valueComponent?: React.ComponentType;
    options: any[];
    optionComponent?: React.ComponentType | React.ReactNode;
    resetValue?: any;
    clearable?: boolean;
    searchable?: boolean;
    onChange?: any;
    placeholder?: string;
    style?: any;
    searchPlaceholder?: string;
    testSelectorName?: string;
    forceDirection?: 'up' | 'down';
    autoSetFocus?: boolean;
    setIsActive?: React.Dispatch<React.SetStateAction<boolean>>;
    disable?: any;
    isError?: boolean;
    roundedSelect?: boolean;
    scrollIntoView?: boolean;
}
interface IState {
    isOpen: boolean;
    direction: string;
}

export class CustomSelect extends React.Component<ICustomSelectProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            direction: 'down',
        };
    }

    selectRef = null;
    getSelectRef = (ref) => {
        this.selectRef = ref;
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.scrollElementIntoView);
    }

    setWindowScroll = () => {
        // Prevent scroll on desktop
        if (window.outerWidth >= 1280) return;
        if (this.state.direction === 'down') {
            document.activeElement.scrollIntoView(!!this.props.scrollIntoView);
        } else {
            window.addEventListener('resize', this.scrollElementIntoView);
        }
    };

    scrollElementIntoView = () => {
        if (document.activeElement.tagName === 'INPUT' && !!this.props.scrollIntoView) {
            document.activeElement.scrollIntoView();
        }
    };
    setActiveState(input: boolean) {
        if (this.props.setIsActive && typeof this.props.setIsActive !== 'undefined') {
            this.props.setIsActive(input);
        }
    }
    handleClick = (event) => {
        event.preventDefault();
        const callback = this.props.scrollIntoView ? this.setWindowScroll : null;
        if (!this.state.isOpen) {
            this.setSelectListDirection(callback);
        }
        this.setState({ isOpen: !this.state.isOpen });
        this.setActiveState(!this.state.isOpen);
    };

    handleChange = (value) => {
        this.hide();
        this.props.onChange(value);
    };

    hide = () => {
        this.setState({ isOpen: false });
        this.setActiveState(false);
    };

    emptyArrow = () => null;

    isFitting = (direction: string) => {
        const boundingRect = this.selectRef.getBoundingClientRect();
        const searchBarHeight = this.props.searchable ? SEARCH_BAR_HEIGHT : 0;

        switch (direction) {
            case 'top':
                return (
                    boundingRect.top - LIST_MAX_HEIGHT - HEADER_HEIGHT - searchBarHeight > 0
                ); /* checks if select is fitting at top*/
            case 'bottom':
            default:
                return (
                    boundingRect.bottom + LIST_MAX_HEIGHT + FILTER_BUTTON_HEIGHT + searchBarHeight <
                    window.innerHeight
                ); /* checks if select is fitting at bottom*/
        }
    };

    setSelectListDirection = (callback) => {
        if (this.props.autoSetFocus) {
            setTimeout(() => this.selectRef?.querySelector('input')?.focus(), 200);
            this.selectRef.focus();
        }
        const direction = this.isFitting('top') ? 'up' : 'down';
        this.setState(
            {
                direction: this.isFitting('bottom')
                    ? 'down'
                    : this.props.forceDirection
                    ? this.props.forceDirection
                    : direction,
            },
            callback,
        );
    };

    render() {
        const selectStyleDefault = this.props.isError ? { border: `1px solid ${vars.pink}` } : {};
        const selectRefStyle: any = this.state.isOpen
            ? {
                  position: 'relative',
                  zIndex: 1,
              }
            : {};
        const roundedSelectStyle = this.props.roundedSelect ? { borderRadius: 4 } : {};
        const selectedOption = this.props.options.find((_) => _?.value === this.props?.value);
        let label = selectedOption?.label;
        if (!label && this.props.options.length > 0) {
            label = this.props.options[0].label;
        }
        return this.props.searchable ? (
            <div
                ref={this.getSelectRef}
                className={this.props.className}
                tabIndex={-1}
                style={selectRefStyle}
                {...TestSelector.getSelector(this.props.testSelectorName)}
            >
                <ClickOutside onClickOutside={this.hide}>
                    <div
                        style={{
                            ...styles.selectedValue,
                            ...roundedSelectStyle,
                        }}
                        onClick={this.handleClick}
                    >
                        {label}
                        <span
                            className={
                                this.state.isOpen ? 'Select-arrow Select-arrow-up' : 'Select-arrow'
                            }
                        />
                    </div>
                    <div style={styles.optionsWrapper}>
                        {this.state.isOpen && (
                            <div style={styles.options}>
                                <div style={styles.optionsContent}>
                                    <Select
                                        {...this.props}
                                        isOpen
                                        isFocused
                                        filterOption={filterOption}
                                        arrowRenderer={this.emptyArrow}
                                        valueComponent={SelectedSearchableValue}
                                        style={selectStyleDefault}
                                        className={`direction-${this.state.direction}`}
                                        onChange={this.handleChange}
                                        noResultsText={__('no result found', 'dreamlines')}
                                        scrollMenuIntoView={false}
                                        onInputKeyDown={(e) => {
                                            if (e.keyCode === 32) {
                                                e.preventDefault();
                                                e.target.value += ' ';
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </ClickOutside>
            </div>
        ) : (
            <div
                className={this.props.className}
                ref={this.getSelectRef}
                tabIndex={-1}
                style={selectRefStyle}
                {...TestSelector.getSelector(this.props.testSelectorName)}
            >
                <Select
                    {...this.props}
                    style={selectStyleDefault}
                    isOpen={false}
                    isFocused={false}
                    className={`direction-${this.state.direction}`}
                    onOpen={this.setSelectListDirection}
                    searchable={false}
                />
            </div>
        );
    }
}

export default CustomSelect;
